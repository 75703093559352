// Create the observer like the examples above

function createIntersectionObserver() {
  return new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate__fadeInUp");
        }
      });
    },
    {
      threshold: 0.05,
    }
  );
}

function initObserver(squares) {
  window.observerInstance = createIntersectionObserver();
  squares.forEach((element) => window.observerInstance.observe(element));
}

(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel.video-collection:not(.slider)").owlCarousel({
      items: 1,
      loop: true,
      lazyLoad: true,

      // autoplay
      autoplay: false,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // Video
      video: true,

      // navigation
      nav: true,
      navText: [
        '<i class="fal fa-chevron-circle-left"></i>',
        '<i class="fal fa-chevron-circle-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: false,

      onChange: resetSlideImage,
      onChanged: fixAnimation,
    });

    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,
      lazyLoad: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 5000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: false,
      navText: [
        '<i class="fal fa-chevron-circle-left"></i>',
        '<i class="fal fa-chevron-circle-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: false,

      onChange: resetSlideImage,
      onChanged: fixAnimation,
      // onInitialized: playVideo,
    });

    // function playVideo(e) {
    //   const carousel = e.target;
    //   const currentSlide = $(carousel).find(".owl-item.active");

    //   currentSlide.find('.owl-video-play-icon').trigger('click');

    //   handleVideoSlide(currentSlide);
    // }

    function resetSlideImage(e) {
      const carousel = e.target;
      const currentSlide = $(carousel).find(".owl-item.active");

      currentSlide.parent().find("iframe").remove();
      currentSlide.find('.item').css("display", "");
    }

    // hide slide bg image on play video
    $(document).on("click", ".owl-video-play-icon", function (e) {
      handleVideoSlide($(this).parent('.owl-video-wrapper'));
    });

    function handleVideoSlide(slide) {
      const owlVideo = slide.find(".owl-video");
      owlVideo.attr("style", owlVideo.attr("style") + "; " + "display: none !important;");
    }

    function fixAnimation(event) {
      var element = event.target;
      var item = event.item.index;
      // get before active item
      var prev_slide = $(element).find(".owl-item").prev();
      if (item == 0) {
        prev_slide = $(element).find(".owl-item:last-child");
      }

      setTimeout(function () {
        // remove classes from before active item
        prev_slide
          .removeClass(
            "animated owl-animated-in fadeIn owl-animated-out fadeOut"
          )
          .css({
            left: "auto",
          });
      }, 1000);
    }

    // slider
    $(".slider").owlCarousel({
      items: 1,
      margin: 15,
      loop: true,
      lazyLoad: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    $(".search-icon").click(function () {
      $(this).parent().find(".form-control").toggleClass("show");
      $(this).toggleClass("hide");
    });
    // sticky
    // require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");
    $(window).scroll(function () {
      var $el = $("body").add(".header");

      if ($(window).scrollTop() > 0) {
        $el.addClass("sticky");
      } else {
        $el.removeClass("sticky");
      }
    });

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // make kasabon in smooth layout sticky
    require("../../vendor/w3media/framework/assets/js/V2/hc-sticky");
    $(".w3media-booking-receipt-widget").hcSticky({
      stickTo: ".content-section",
      top: 140,
      responsive: {
        992: {
          disable: true,
        },
      },
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 0);
      }
    });

    // faq fix
    let faqlinks = document.querySelectorAll(".faq-category a");
    faqlinks.forEach((link) => {
      if (link.id) {
        link.id = "";
        link.href = "#";
      }
    });

    // prevent scrolling on #TommyBookingSupport page
    $(window).on("hashchange", function (e) {
      if ($("#TommyBookingSupport").length > 0) {
        e.preventDefault();

        // disable scrolling
        $("body").css("overflow", "hidden");

        // set current scroll position
        $("html, body").animate({
          scrollTop: $("#TommyBookingSupport").offset().top - 120,
        });

        // enable scrolling
        setTimeout(() => {
          $("body").css("overflow", "auto");
        }, 10);
      }
    });

    // open mega menu
    $(".navbar-toggler").on("click", function (e) {
      $(this).attr("aria-expanded", (_, attr) =>
        attr == "false" ? "true" : "false"
      );
      $(".mega-menu-holder").slideToggle();
      $("body").toggleClass("mega-menu-opened");
    });

    // slide menu
    $(".mega-menu-holder .dropdown-toggle").on("click", function (e) {
      if ($(window).width() <= 1200) {
        // close another menu lists
        $(this).parent(".nav-item").siblings().find(".dropdown-menu").slideUp();

        // open clicked list
        $(this).next(".dropdown-menu").slideToggle();
      }
    });

    // toggle mage menu footer list
    $(".mega-menu-footer .column > .footer-list").on("click", function (e) {
      if ($(window).width() <= 992) {
        // close another menu lists
        $(this).siblings(".footer-list").removeClass("show");
        $(this).siblings(".footer-list").find(".list").slideUp();

        // open clicked list
        $(this).toggleClass("show");
        $(this).find(".list").slideToggle();
      }
    });

    // Close menu when click on body
    $(document).on("click", function (e) {
      if ($(e.target).hasClass("mega-menu-opened")) {
        $(".navbar-toggler").click();
      }
    });

    // Get multiple elements instead of a single one using "querySelectorAll"
    const squares = document.querySelectorAll(
      ".page-block.title, .page-block.subtitle, .wysiwyg, .grid .card, .video-collection, .eyecatcher-label, .contact-list, .review, .footer-logolink, .footer-text:not(.mega-footer-text), .gallery-item, .form"
    );

    // Loop over the elements and add each one to the observer
    initObserver(squares);
  });
})(jQuery);
